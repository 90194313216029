import { createBrowserRouter, type RouteObject } from 'react-router-dom';

export const routes = createBrowserRouter(
  [
    {
      lazy: async () => await import('./pages/layout'),
      children: [
        {
          lazy: async () => await import('./pages/(auth)/layout'),
          children: [
            {
              path: '/login',
              title: 'Login',
              lazy: async () => await import('./pages/(auth)/login/page'),
            },
            {
              path: '/401',
              title: 'Unauthorized',
              lazy: async () => await import('./pages/(auth)/401/page'),
            },
          ],
        },
        {
          lazy: async () => await import('./pages/(protected)/layout'),
          children: [
            {
              lazy: async () => await import('./pages/(protected)/(project)/layout'),
              children: [
                {
                  lazy: async () => await import('./pages/(protected)/(project)/overview/layout'),
                  children: [
                    {
                      index: true,
                      path: '/',
                      title: 'Overview',
                      label: 'Overview',
                      lazy: async () => await import('./pages/(protected)/(project)/overview/page'),
                    },
                  ],
                },
                {
                  lazy: async () => await import('./pages/(protected)/(project)/tasks/layout'),
                  children: [
                    {
                      path: '/tasks',
                      title: 'Tasks',
                      label: 'Tasks',
                      lazy: async () => await import('./pages/(protected)/(project)/tasks/page'),
                    },
                    {
                      lazy: async () => await import('./pages/(protected)/(project)/tasks/details/layout'),
                      children: [
                        {
                          label: 'Task',
                          path: '/tasks/:id',
                          lazy: async () => await import('./pages/(protected)/(project)/tasks/details/page'),
                        },
                        {
                          label: 'Task',
                          path: '/tasks/:id/definition',
                          lazy: async () => await import('./pages/(protected)/(project)/tasks/details/definition/page'),
                        },
                      ],
                    },
                  ],
                },
                {
                  lazy: async () => await import('./pages/(protected)/(project)/runs/layout'),
                  children: [
                    {
                      path: '/runs',
                      title: 'Task Runs',
                      label: 'Runs',
                      lazy: async () => await import('./pages/(protected)/(project)/runs/page'),
                    },
                    {
                      lazy: async () => await import('./pages/(protected)/(project)/runs/details/layout'),
                      children: [
                        {
                          path: '/runs/:id',
                          title: 'Run',
                          label: 'Run',
                          lazy: async () => await import('./pages/(protected)/(project)/runs/details/page'),
                        },
                        {
                          path: '/runs/:id/inputs',
                          title: 'Run inputs',
                          label: 'Run inputs',
                          lazy: async () => await import('./pages/(protected)/(project)/runs/details/inputs/page'),
                        },
                        {
                          path: '/runs/:id/results',
                          title: 'Run results',
                          label: 'Run results',
                          lazy: async () => await import('./pages/(protected)/(project)/runs/details/results/page'),
                        },
                      ],
                    },
                  ],
                },
                {
                  lazy: async () => await import('./pages/(protected)/(project)/data-sources/layout'),
                  children: [
                    {
                      path: '/data-sources',
                      title: 'Data Sources',
                      label: 'Data Sources',
                      lazy: async () => await import('./pages/(protected)/(project)/data-sources/page'),
                    },
                    {
                      lazy: async () => await import('./pages/(protected)/(project)/data-sources/details/layout'),
                      children: [
                        {
                          path: '/data-sources/:id',
                          title: 'Data Source',
                          label: 'Data Source',
                          lazy: async () => await import('./pages/(protected)/(project)/data-sources/details/page'),
                        },
                      ],
                    },
                  ],
                },
                {
                  lazy: async () => await import('./pages/(protected)/(project)/resources/layout'),
                  children: [
                    {
                      path: '/resources',
                      title: 'Resources',
                      label: 'Resources',
                      lazy: async () => await import('./pages/(protected)/(project)/resources/page'),
                    },
                  ],
                },
                {
                  lazy: async () => await import('./pages/(protected)/(project)/artifacts/layout'),
                  children: [
                    {
                      path: '/artifacts',
                      title: 'Published Artifacts',
                      label: 'Artifacts',
                      lazy: async () => await import('./pages/(protected)/(project)/artifacts/page'),
                    },
                  ],
                },
              ],
            },
            {
              lazy: async () => await import('./pages/(protected)/(graph)/layout'),
              children: [
                {
                  path: '/tasks/:id/graph',
                  title: 'Graph',
                  label: 'Graph',
                  lazy: async () => await import('./pages/(protected)/(graph)/tasks/page'),
                },
                {
                  path: '/runs/:id/graph',
                  title: 'Graph',
                  label: 'Graph',
                  lazy: async () => await import('./pages/(protected)/(graph)/runs/page'),
                },
              ],
            },
          ],
        },
        {},
      ],
    },
  ] as RouteObject[],
);
