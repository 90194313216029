import {
  createApi,
  type BaseQueryFn,
} from '@reduxjs/toolkit/query/react';
import isEmpty from 'lodash.isempty';

import {
  plato,
  type PlatoRequestConfig,
} from '@/services/plato';

const baseQuery = (): BaseQueryFn<PlatoRequestConfig, unknown, unknown> => plato;

export enum TagTypes {
  TASK_DETAILS = 'TASK_DETAILS',
  TASK_INPUTS = 'TASK_INPUTS',
}

export const tasks = createApi({
  reducerPath: 'tasks',
  baseQuery: baseQuery(),
  tagTypes: [
    TagTypes.TASK_DETAILS,
    TagTypes.TASK_INPUTS,
  ],
  endpoints(builder) {
    const getTask = builder.query<any, string>({
      providesTags: [TagTypes.TASK_DETAILS],
      query(urn) {
        return {
          url: `/api/pipelines/details/${urn}`,
          method: 'GET',
        };
      },
    });

    const getTaskInputs = builder.query<any, string>({
      providesTags: [TagTypes.TASK_INPUTS],
      async queryFn(urn, api) {
        const { data: { create_request } } = await api.dispatch(
          tasks.endpoints.getTask.initiate(urn),
        );

        if (isEmpty(create_request)) {
          return { data: {} };
        }

        const data = {
          content: JSON.stringify(create_request),
          language: 'json',
        };

        return { data };
      },
    });

    return {
      getTask,
      getTaskInputs,
    };
  },
});
