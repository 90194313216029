import { createApi, type BaseQueryFn } from '@reduxjs/toolkit/query/react';

import {
  plato,
  type Plato,
  type PlatoRequestConfig,
} from '@/services/plato';

const baseQuery = (): BaseQueryFn<PlatoRequestConfig, unknown, unknown> => plato;

enum TagTypes {
  RESOURCES_LIST = 'RESOURCES_LIST',
  AVAILABLE_RESOURCES_TYPES = 'AVAILABLE_RESOURCES_TYPES',
  RESOURCE_CONFIG = 'RESOURCES_RESOURCE_CONFIG',
}

const RESOURCE_LIST_DEFAULT_TAG = {
  id: 'RESOURCES_LIST',
  type: TagTypes.RESOURCES_LIST,
} as const;

const RESOURCE_CONFIG_DEFAULT_TAG = {
  id: 'RESOURCE_CONFIG_BY_URN',
  type: TagTypes.RESOURCE_CONFIG,
} as const;

export const resources = createApi({
  reducerPath: 'resources',
  baseQuery: baseQuery(),
  tagTypes: [
    TagTypes.RESOURCES_LIST,
    TagTypes.AVAILABLE_RESOURCES_TYPES,
    TagTypes.RESOURCE_CONFIG,
  ],
  endpoints(builder) {
    const getResources = builder.query<Plato.API.ResourcesResponseData, void>({
      providesTags(result) {
        if (!result) {
          return [RESOURCE_LIST_DEFAULT_TAG];
        }

        const providesTagsById = result.map((resource) => ({
          id: resource.urn,
          type: TagTypes.RESOURCES_LIST as const,
        }));

        return [...providesTagsById, RESOURCE_LIST_DEFAULT_TAG];
      },
      query: () => ({
        url: '/api/resources',
        method: 'GET',
      }),
    });

    const getAvailableResourcesTypes = builder.query<Plato.API.AvailableResourcesTypesResponseData, void>({
      providesTags: [TagTypes.AVAILABLE_RESOURCES_TYPES],
      query: () => ({
        url: '/api/resources/types',
        method: 'GET',
      }),
    });

    const createResource = builder.mutation<Plato.API.CreateResourceResponseData, Plato.API.CreateResourceRequestData>({
      invalidatesTags: [TagTypes.RESOURCES_LIST],
      query: (data) => ({
        url: '/api/resources',
        method: 'POST',
        data,
      }),
    });

    type UpdateResourceRequestData = Plato.API.UpdateResourceRequestData & {
      urn: string,
    };

    const updateResource = builder.mutation<Plato.API.UpdateResourceResponseData, UpdateResourceRequestData>({
      invalidatesTags(
        result,
        error,
        { urn },
      ) {
        return [
          TagTypes.RESOURCES_LIST as const,
          TagTypes.RESOURCE_CONFIG as const,
        ].map((type) => ({ type, id: urn }));
      },
      query: ({ urn, ...data }) => ({
        url: `/api/resources/${urn}`,
        method: 'POST',
        data,
      }),
    });

    const deleteResource = builder.mutation<Plato.API.ResourceDeleteResponseData, string>({
      invalidatesTags(
        result,
        error,
        urn,
      ) {
        return [
          TagTypes.RESOURCES_LIST as const,
          TagTypes.RESOURCE_CONFIG as const,
        ].map((type) => ({ type, id: urn }));
      },
      query: (urn) => ({
        url: `/api/resources/${urn}/config`,
        method: 'DELETE',
      }),
    });

    const getResource = builder.query<Plato.API.ResourceResponseData, string>({
      query: (urn) => ({
        url: `/api/resources/${urn}`,
        method: 'GET',
      }),
    });

    const getResourceConfig = builder.query<Plato.API.ResourceConfigResponseData, string>({
      providesTags(result) {
        if (!result) {
          return [RESOURCE_CONFIG_DEFAULT_TAG];
        }

        const providesTagById = {
          id: result.urn,
          type: TagTypes.RESOURCE_CONFIG,
        } as const;

        return [providesTagById, RESOURCE_CONFIG_DEFAULT_TAG];
      },
      query: (urn) => ({
        url: `/api/resources/${urn}/config`,
        method: 'GET',
      }),
    });

    return {
      getResources,
      getAvailableResourcesTypes,
      createResource,
      updateResource,
      deleteResource,
      getResource,
      getResourceConfig,
    };
  },
});
