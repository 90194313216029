import {
  createApi,
  QueryReturnValue,
  type BaseQueryFn,
} from '@reduxjs/toolkit/query/react';
import {
  plato,
  type PlatoRequestConfig,
  type Plato,
} from '@/services/plato';
import { getPropertyDetails } from '@/contexts/backend';
import isEmpty from 'lodash.isempty';

const baseQuery = (): BaseQueryFn<PlatoRequestConfig, unknown, unknown> => plato;

export enum TagTypes {
  RUN_DETAILS = 'RUN_DETAILS',
  RUN_RESULTS = 'RUN_RESULTS',
  RUN_INPUTS = 'RUN_INPUTS',
}

export const runs = createApi({
  reducerPath: 'runs',
  baseQuery: baseQuery(),
  tagTypes: [
    TagTypes.RUN_DETAILS,
    TagTypes.RUN_RESULTS,
    TagTypes.RUN_INPUTS,
  ],
  endpoints(builder) {
    /**
     * TODO: Add query types.
     */
    const getRun = builder.query<any, string>({
      providesTags: [TagTypes.RUN_DETAILS],
      /**
       * TODO: Need to transform the response, change properties names e etc.
       */
      query(urn) {
        return {
          url: `/api/pipelines/runs/${urn}`,
          method: 'GET',
        };
      },
    });

    type RunResultsData = {
      content?: string,
      language?: string,
    } | string;

    const getRunResults = builder.query<RunResultsData, string>({
      providesTags: [TagTypes.RUN_RESULTS],
      async queryFn(urn, api) {
        const { data: { results_urn } } = await api.dispatch(
          runs.endpoints.getRun.initiate(urn),
        );

        if (isEmpty(results_urn)) {
          return { data: {} };
        }

        const data = await getPropertyDetails(results_urn);

        return { data };
      },
    });

    type RunInputsData = {
      directives?: {
        language: string,
        content: string,
      },
      subjects?: {
        [urn: string]: {
          language: string,
          content: string,
        },
      },
      referenceData?: {
        [urn: string]: {
          language: string,
          content: string,
        },
      },
      hyperparams?: {
        language: string,
        content: string,
      },
    };

    const getRunInputs = builder.query<RunInputsData, string>({
      providesTags: [TagTypes.RUN_INPUTS],
      async queryFn(
        urn,
        api,
        options,
        baseQuery,
      ) {
        const { data: { pipeline_urn } } = await api.dispatch(
          runs.endpoints.getRun.initiate(urn),
        );

        const query = await baseQuery({
          url: `/api/pipelines/runs/${urn}/inputs`,
          method: 'GET',
          params: {
            pipeline_run_urn_str: pipeline_urn,
          } satisfies Plato.API.RunInputsParams,
        }) as QueryReturnValue<Plato.API.RunInputsData>;

        const data: RunInputsData = {};

        if (query.data?.['subject_urns']) {
          data.subjects = {};

          for await (const subject of query.data['subject_urns']) {
            data.subjects[subject] = await getPropertyDetails(subject);
          }
        }

        if (query.data?.['reference_data']) {
          data.referenceData = {};

          for await (const reference of query.data['reference_data']) {
            data.referenceData[reference] = await getPropertyDetails(reference);
          }
        }

        if (query.data?.hyperparams) {
          data.hyperparams = {
            language: 'json',
            content: JSON.stringify(query.data.hyperparams),
          };
        }

        if (query.data?.directives) {
          data.directives = {
            language: 'json',
            content: JSON.stringify(query.data.directives),
          };
        }

        return { data };
      },
    });

    return {
      getRun,
      getRunResults,
      getRunInputs,
    };
  },
});
