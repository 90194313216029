import { useAsync } from "react-async-hook";
import { components } from "../api";
import deepmerge from "deepmerge";
import pick from 'lodash.pick';
import {
  plato,
  translateProps,
  type Plato,
} from '@/services/plato';
import { type ChartData } from 'chart.js';
import { AxiosResponse } from "axios";

export type PipelineInfo = components['schemas']['PipelineInfo']
export type PipelineView = components['schemas']['PipelineView']
export type PipelineRunInfo = components['schemas']['PipelineRunResponse']

export function useListPipelines() {
  return useAsync<PipelineInfo[]>(async () => {
    try {
      const { data } = await plato.get<components["schemas"]["PipelineListResponse"]>(
        `/api/pipelines/list`
      );

      return data.pipelines;
    } catch (error) {
      return null;
    }
  }, []);
}

export type RunsResponse = Promise<
  & Pick<
    components['schemas']['PipelineRunsResponse'],
    | 'items'
    | 'page'
    | 'total'
  >
  & {
    limit: components['schemas']['PipelineRunsResponse']['page_size']
    pipeline?: string,
  }
  | {}
>;

type RunsOptions = {
  params?: {
    page?: string | number,
    limit?: string | number,
    sort?: string,
    orderBy?: string,
    status?: string,
    pipeline?: string,
  },
};

export async function getRuns(options: RunsOptions = {}): RunsResponse {
  const opts = deepmerge(
    /**
     * Default arguments.
     */
    { params: {} },
    options,
  );

  try {
    const params = translateProps(
      opts.params,
      // @ts-expect-error Need to fix inference issue.
      {
        limit: 'page_size',
        orderBy: 'order_by',
        sort: 'order_direction',
      },
    );

    const response = await plato.get<components['schemas']['PipelineRunsResponse']>(
      '/api/pipelines/runs',
      { params },
    );

    const transform = translateProps(
      {
        ...response.data,
        pipeline: opts.params?.pipeline,
      },
      // @ts-expect-error Need to fix inference issue.
      { page_size: 'limit' },
    );

    return transform;
  } catch (error) {
    console.error(error);

    return {};
  }
}

export function usePipeline(pipelineId: string) {
  return useAsync<PipelineView>(async () => {
    try {
      const { data } = await plato.get<PipelineView>(
        `/api/pipelines/view/${pipelineId}`
      );

      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [pipelineId]);
}

export async function getPipelineMetrics(
  /**
   * Pipeline urn.
   */
  pipeline: string,
): MetricsResponse {
  try {
    const { data } = await plato.get(
      `/api/pipelines/${pipeline}/metrics`,
    );

    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

type PropertyDetails = any;

export async function getPropertyDetails(urn: string): Promise<PropertyDetails> {
  try {
    const { data, ...response } = await plato.get<PropertyDetails>(
      `/api/viewer/object/${urn}`,
    );

    if (response.status === 404) {
      return 'Object not found.';
    }

    const contentType = response.headers['content-type'];

    if (contentType === 'application/json') {
      return {
        content: data,
        language: 'json',
      };
    }

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export type MetricsResponse = Promise<{
  metrics: {
    type: string,
    title: string,
    description: string,
    value: number,
    data: ChartData,
  }[],
} | {}>;

export async function getRunMetrics(
  /**
   * Pipeline urn.
   */
  pipeline: string,
  /**
   * Run urn.
   */
  run: string,
): MetricsResponse {
  try {
    const { data } = await plato.get(
      `/api/pipelines/runs/${run}/metrics`,
    );

    return data;
  } catch (error) {
    console.error(error);
    return {};
  }
}

export async function runAction(
  { urn, action }: { urn: string, action: string }
) {
  try {
    const { data } = await plato.post<PropertyDetails>(
      `/api/pipelines/versions/action`,
      {
        body: JSON.stringify({
          action,
          version_urn: urn,
        }),
      },
    );

    return data;
  } catch (error) {
    console.error(error);

    return {
      status: 'error',
      message: 'An error occurred while running the action.',
    };
  }
}

type DataSourcesResponseData = {
  urn: string,
  name: string,
  description: string,
  type: string,
  status: string,
}[];

type DataSourcesResponse = Promise<DataSourcesResponseData>;

export async function getDataSources(): DataSourcesResponse {
  try {
    const response = await plato.get<components['schemas']['DataSourceResponse'][]>(
      `/api/data-sources/list`,
    );

    const data: DataSourcesResponseData = response.data.map((item) => (
      pick(
        translateProps(
          item,
          // @ts-expect-error Need to fix inference issue.
          {
            type_str: 'type',
            status_str: 'status',
          },
        ),
        [
          'urn',
          'name',
          'description',
          'type',
          'status',
        ],
      )
    ));

    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

type DataSourceResponseData = {
  urn: string,
  name: string,
  description: string,
  type: string,
  status: string,
};

type DataSourceResponse = Promise<DataSourceResponseData>;

export async function getDataSource(urn: string): DataSourceResponse {
  try {
    const response = await plato.get<components['schemas']['DataSourceResponse']>(
      `/api/data-sources/${urn}`,
    );

    const data: DataSourceResponseData = pick(
      translateProps(
        response.data,
        // @ts-expect-error Need to fix inference issue.
        {
          type_str: 'type',
          status_str: 'status',
        },
      ),
      [
        'urn',
        'name',
        'description',
        'type',
        'status',
      ],
    );

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getResourcesList(): Promise<components['schemas']['ResourceResponse'][]> {
  try {
    const { data } = await plato.get<components['schemas']['ResourceResponse'][]>(
      `/api/resources/list`,
    );

    return data;
  } catch (error) {
    console.error(error);
    return [];
  }
}

type ArtifactsOptions = {
  params?: {
    urn?: string,
  },
};

type ArtifactsResponseData = {
  items: {
    href: string,
    name: string,
    mimeType: string,
    icon: string,
    urn: string,
  }[],
};

export type ArtifactsResponse = Promise<ArtifactsResponseData>;

export async function getArtifacts(options: ArtifactsOptions = {}): ArtifactsResponse {
  options = deepmerge(
    /**
     * Default query parameters.
     */
    { params: {} },
    options,
  );

  const params = translateProps(
    options.params,
    { urn: 'pipeline_run_urn' },
  );

  try {
    const response = await plato.get<components['schemas']['PublishedArtifactResponse'][]>(
      '/api/published-artifacts',
      { params },
    );

    const items: ArtifactsResponseData['items'] = response.data.map((artifact) => (
      pick(
        translateProps(
          artifact,
          // @ts-expect-error Need to fix inference issue.
          {
            mime_type: 'mimeType',
          },
        ),
        [
          'urn',
          'href',
          'name',
          'mimeType',
          'icon',
        ],
      )
    ));

    const data: ArtifactsResponseData = { items };

    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}

type DownloadOptions = {
  href: string;
  mimeType: string;
  name: string;
};

export async function downloadArtifact(options: DownloadOptions): Promise<void> {
  const {
    href,
    mimeType,
    name,
  } = options;

  try {
    const response = await plato.get(
      href,
      { responseType: 'blob' },
    );

    const blob = await response.data;

    const url = window.URL.createObjectURL(
      new Blob([blob], { type: mimeType }),
    );

    const link = document.createElement('a');

    link.href = url;
    link.download = name;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error downloading artifact:', error);
  }
}
