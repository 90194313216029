import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { runs } from './runs';
import { tasks } from './tasks';
import { resources } from './resources';
export const store = configureStore({
  reducer: {
    [runs.reducerPath]: runs.reducer,
    [tasks.reducerPath]: tasks.reducer,
    [resources.reducerPath]: resources.reducer,
  },
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware().concat(
      runs.middleware,
      tasks.middleware,
      resources.middleware,
    )
  ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

setupListeners(store.dispatch);
